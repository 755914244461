var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("server-time-clock"),
      _c("phone-help"),
      _c("br"),
      _c(
        "transition",
        {
          attrs: {
            name: "fade",
            mode: "out-in",
          },
        },
        [
          _vm.classroomList.length > 0
            ? _c(
                "div",
                {
                  key: "classroomList",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "reg-classroom",
                    },
                    [
                      _c(
                        "transition-group",
                        {
                          attrs: {
                            name: "fade",
                          },
                        },
                        _vm._l(_vm.classroomList, function (classroomData) {
                          return _c(
                            "div",
                            {
                              key: classroomData.class_applications,
                            },
                            [
                              _c("vip-classroom", {
                                attrs: {
                                  show_consultant_img: true,
                                  classroom_type: "normal",
                                  classroomData: classroomData,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                  _c("vip-week-class-count"),
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.classroomPaination.last_page,
                      "per-page": 1,
                      align: "center",
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                {
                  key: "empty",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "reg-no-booking",
                    },
                    [
                      _c("span", {
                        staticClass: "mt-3",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("classroom.no_class_message")
                          ),
                        },
                      }),
                    ]
                  ),
                ]
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }