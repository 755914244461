var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        staticClass: "are-classroom-tooltip",
      },
      [
        _c(
          "p",
          {
            staticClass: "txt-classroom-phone",
          },
          [
            _c("font-awesome-icon", {
              staticClass: "mr-1",
              attrs: {
                icon: "phone",
              },
            }),
            _vm._v(_vm._s(_vm.$t("classroom.calling_help"))),
            _c(
              "a",
              {
                staticClass: "lnk-classroom-phone",
                attrs: {
                  href: "tel:" + _vm.company_phone,
                },
              },
              [_vm._v(_vm._s(_vm.company_phone))]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }