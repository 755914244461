var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "reg-class-total",
    },
    [
      _c(
        "div",
        {
          staticClass: "are-class-total",
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("class.week_finished_class")) +
              "  : " +
              _vm._s(_vm.ClassRecordsWeekly.week_class_count) +
              " " +
              _vm._s(_vm.$t("class.class")) +
              " "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "are-class-total",
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("class.behind_class")) +
              "  : " +
              _vm._s(_vm.ClassRecordsWeekly.week_balance_class) +
              " " +
              _vm._s(_vm.$t("class.class")) +
              " "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }